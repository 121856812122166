/* global Component */
class TermsAndConditionsPage extends Component {

    static  name() {
        return "termsAndConditions";
    }

    static componentName() {
        return "termsAndConditions";
    }

    getTemplate() {
        return  `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">{{tr('Terms and conditions')}}</h5>
                          <hr>
                          <div>
                              <strong>Creación de usuario:</strong>
                              <span>Para poder realizar una compra en Ofidirect.com es necesario que se registre como usuario completando el siguiente link :</span>
                              <button class="btn secondary-btn" @click="$router.push('/login');">{{tr('Create new user')}}</button>
                          </div>
                          <div class="mt-2">
                              <strong>Medios de pago:</strong>
                              <p class="m-0">Mercado Pago</p>
                              <p class="m-0">Depósito bancario</p>
                              <p class="m-0">Transferencia bancaria</p>
                          </div>
                          <div class="mt-2">
                              <strong>Precios:</strong>
                              <p>Los precios publicados no incluyen IVA. Ofidirect se reserva el derecho de modificar los precios publicados en éste sitio web, en cualquiera de sus publicaciones gráficas o mensajes por e-mail, sin previo aviso.</p>
                          </div>
                          <div class="mt-2">
                              <strong>Entrega:</strong>
                              <p class="m-0">- En CABA el monto mínimo para entregas sin cargo es de $40.000+IVA</p>
                              <p>- En GBA el monto mínimo para entregas sin cargo es de $40.000+IVA</p>
                              <p class="m-0">Aquellos pedidos que no lleguen al monto mínimo tendrán un costo de envío de $5.000+IVA en CABA o en GBA.</p>
                              <p class="m-0">Los pedidos para CABA y GBA se entregan dentro de las 24 hs. de lunes a viernes en el horario de 9:00 a 18:00 hs.</p>
                              <p class="m-0">Para entregas al interior del país consultar por las diferentes opciones.</p>
                              <p class="m-0">Entrega Express: es un servicio de entrega en el día disponible sólo para CABA. Este servicio tiene un cargo adicional de $8.500+IVA. Se podrá solicitar este servicio hasta las 12:00 hs.</p>
                          </div>
                          <div class="mt-2">
                              <strong>Devoluciones:</strong>
                              <p>Las devoluciones sólo serán aceptadas dentro de los 5 días hábiles posteriores a la recepción del pedido. Para efectuar cambio de mercadería por encontrarse la misma fallada, el producto se deberá encontrar en perfecto estado de conservación, con el embalaje correspondiente, accesorios, manuales instructivos en su interior, etc. En caso de tratarse de productos pedidos por error o entregados por error, el embalaje deberá estar sin abrir. Para productos de marca Hewlett Packard (HP), los reclamos o cambios deberán hacerse comunicándose con el Centro de Asistencia Técnica HP al 800-555-5000.</p>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }

}
TermsAndConditionsPage.registerComponent();